import * as Sentry from '@sentry/react';

import { IS_SERVER } from '@/renderer/utils/ssr';

function initSentry() {
  Sentry.init({
    dsn: 'https://ca1e72a71d4442a2349e0d74bcfec1fa@o4506110349410304.ingest.us.sentry.io/4506110351900672',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/[a-z.]*raspicks\.com\//],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (!IS_SERVER && !!import.meta.env.PROD && `${location.hostname}`.endsWith('raspicks.com')) {
  initSentry();
}
